import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";
import { alpha } from "@mui/material/styles";

export const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    padding: "0 10px 20px",
    width: "100%",
    maxWidth: "20%",
    '@media (max-width: 1461px)': {
      maxWidth: "25%",
    },
    '@media (max-width: 1201px)': {
      maxWidth: "33.3333%",
    },
    '@media (max-width: 841px)': {
      maxWidth: "50%",
    },
    '@media (max-width: 767px)': {
      padding: "0 10px 8px",
    },
    '@media (max-width: 561px)': {
      maxWidth: "100%",
    },
  },

  paper: {
    width: "100%",
    padding: 16,
    minHeight: 88,
    '@media (min-width: 768px)': {
      minHeight: 102,
    },
    '@media (max-width: 767px)': {
      padding: '8px 16px',
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  value: {
    fontWeight: "700"
  },
  comparison: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    borderRadius: 4,
    background: `${alpha(theme.palette.action.disabled, 0.08)}`,
    color: theme.palette.action.active,
    '&.success': {
      background: `${alpha(theme.palette.success.main, 0.08)}`,
      color: theme.palette.success.main,
    },
    '&.error': {
      background: `${alpha(theme.palette.error.main, 0.08)}`,
      color: theme.palette.error.main,
    },
  },

  blockEmpty: {
    padding: "0 10px 20px",
    width: "100%",
    maxWidth: "20%",
    '@media (max-width: 1461px)': {
      maxWidth: "25%",
    },
    '@media (max-width: 1201px)': {
      maxWidth: "33.3333%",
    },
    '@media (max-width: 841px)': {
      maxWidth: "50%",
    },
    '@media (max-width: 767px)': {
      padding: "0 10px 8px",
    },
    '@media (max-width: 561px)': {
      maxWidth: "100%",
    },
  },
  paperEmpty: {
    width: "100%",
    padding: 16,
    '@media (min-width: 768px)': {
      minHeight: 102,
    },
    '@media (max-width: 767px)': {
      padding: '8px 16px',
    },
  },
  headerEmpty: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
    '@media (max-width: 767px)': {
      marginBottom: 0,
    }
  },
}));
