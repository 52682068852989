//@ts-nocheck
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/system';
import { Button } from "@mui/material";

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: theme.palette.gradientButton.background,
      overflow: 'hidden',
      boxShadow: 'none',
      position: 'relative',
      '&:before': {
        content: '"-"',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        fontSize: 0,
        opacity: 0,
        //@ts-ignore
        background: theme.palette.gradientButton.hover,
        transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        zIndex: 1,
        //borderLeftColor: theme.palette.text.disabled,
      },
      '&:hover': {
        boxShadow: 'none',
        //boxShadow: theme.shadow.md,
        //background: 'linear-gradient(45deg, #000 0%, #FF0000 50%)',
        '&:before': {
          opacity: 1,
        }
      },
      "&.Mui-disabled": {
        background: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled
      }
    },

    text: {
      position: 'relative',
      zIndex: 2,
    },
  }
});

export const useStylesDefault = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
}));


export const CustomButton = styled(Button)(({ theme }) => ({
  background: theme.palette.gradientButton.background,
  overflow: 'hidden',
  boxShadow: 'none',
  position: 'relative',
  '&:before': {
    content: '"-"',
    display: 'block',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    fontSize: 0,
    opacity: 0,
    //@ts-ignore
    background: theme.palette.gradientButton.hover,
    transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    zIndex: 1,
    //borderLeftColor: theme.palette.text.disabled,
  },
  '&:hover': {
    boxShadow: 'none',
    //boxShadow: theme.shadow.md,
    //background: 'linear-gradient(45deg, #000 0%, #FF0000 50%)',
    '&:before': {
      opacity: 1,
    }
  },
  "&.Mui-disabled": {
    background: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled
  },
  '> *': {
    zIndex: 2,
  }
}));
