//core
import { createSelector } from "@reduxjs/toolkit";

//store
import {RootState} from "../../../index";

// state
const summaryReportSelector = (state: RootState) => state.admin.summary_report_v2;

const summaryReportOptionsSelector = (state: RootState) => state.admin.summary_report_v2.options;
const summaryReportOptionsDataSelector = (state: RootState) => state.admin.summary_report_v2.options.data;
const summaryReportOptionsErrorSelector = (state: RootState) => state.admin.summary_report_v2.options.error;
const summaryReportOptionsFetchingSelector = (state: RootState) => state.admin.summary_report_v2.options.isFetching;

const summaryReportListSelector = (state: RootState) => state.admin.summary_report_v2.list;
const summaryReportListDataSelector = (state: RootState) => state.admin.summary_report_v2.list.data;
const summaryReportListErrorSelector = (state: RootState) => state.admin.summary_report_v2.list.error;
const summaryReportListFetchingSelector = (state: RootState) => state.admin.summary_report_v2.list.isFetching;

const summaryReportDownloadSelector = (state: RootState) => state.admin.summary_report_v2.download;
const summaryReportDownloadDataSelector = (state: RootState) => state.admin.summary_report_v2.download.data;
const summaryReportDownloadErrorSelector = (state: RootState) => state.admin.summary_report_v2.download.error;
const summaryReportDownloadFetchingSelector = (state: RootState) => state.admin.summary_report_v2.download.isFetching;

const summaryReportAffiliatesSelector = (state: RootState) => state.admin.summary_report_v2.affiliates;
const summaryReportAffiliatesDataSelector = (state: RootState) => state.admin.summary_report_v2.affiliates.data;
const summaryReportAffiliatesErrorSelector = (state: RootState) => state.admin.summary_report_v2.affiliates.error;
const summaryReportAffiliatesFetchingSelector = (state: RootState) => state.admin.summary_report_v2.affiliates.isFetching;

const summaryReportTrackersSelector = (state: RootState) => state.admin.summary_report_v2.trackers;
const summaryReportTrackersDataSelector = (state: RootState) => state.admin.summary_report_v2.trackers.data;
const summaryReportTrackersErrorSelector = (state: RootState) => state.admin.summary_report_v2.trackers.error;
const summaryReportTrackersFetchingSelector = (state: RootState) => state.admin.summary_report_v2.trackers.isFetching;

const summaryReportDealsSelector = (state: RootState) => state.admin.summary_report_v2.deals;
const summaryReportDealsDataSelector = (state: RootState) => state.admin.summary_report_v2.deals.data;
const summaryReportDealsErrorSelector = (state: RootState) => state.admin.summary_report_v2.deals.error;
const summaryReportDealsFetchingSelector = (state: RootState) => state.admin.summary_report_v2.deals.isFetching;

const summaryReportCreativesSelector = (state: RootState) => state.admin.summary_report_v2.creatives;
const summaryReportCreativesDataSelector = (state: RootState) => state.admin.summary_report_v2.creatives.data;
const summaryReportCreativesErrorSelector = (state: RootState) => state.admin.summary_report_v2.creatives.error;
const summaryReportCreativesFetchingSelector = (state: RootState) => state.admin.summary_report_v2.creatives.isFetching;

const summaryReportProductsSelector = (state: RootState) => state.admin.summary_report_v2.products;
const summaryReportProductsDataSelector = (state: RootState) => state.admin.summary_report_v2.products.data;
const summaryReportProductsErrorSelector = (state: RootState) => state.admin.summary_report_v2.products.error;
const summaryReportProductsFetchingSelector = (state: RootState) => state.admin.summary_report_v2.products.isFetching;

const summaryReportManagersSelector = (state: RootState) => state.admin.summary_report_v2.managers;
const summaryReportManagersDataSelector = (state: RootState) => state.admin.summary_report_v2.managers.data;
const summaryReportManagersErrorSelector = (state: RootState) => state.admin.summary_report_v2.managers.error;
const summaryReportManagersFetchingSelector = (state: RootState) => state.admin.summary_report_v2.managers.isFetching;

const summaryReportBrandsSelector = (state: RootState) => state.admin.summary_report_v2.brands;
const summaryReportBrandsDataSelector = (state: RootState) => state.admin.summary_report_v2.brands.data;
const summaryReportBrandsErrorSelector = (state: RootState) => state.admin.summary_report_v2.brands.error;
const summaryReportBrandsFetchingSelector = (state: RootState) => state.admin.summary_report_v2.brands.isFetching;

const summaryReportCurrenciesSelector = (state: RootState) => state.admin.summary_report_v2.currencies;
const summaryReportCurrenciesDataSelector = (state: RootState) => state.admin.summary_report_v2.currencies.data;
const summaryReportCurrenciesErrorSelector = (state: RootState) => state.admin.summary_report_v2.currencies.error;
const summaryReportCurrenciesFetchingSelector = (state: RootState) => state.admin.summary_report_v2.currencies.isFetching;

const summaryReportPlatformsSelector = (state: RootState) => state.admin.summary_report_v2.platforms;
const summaryReportPlatformsDataSelector = (state: RootState) => state.admin.summary_report_v2.platforms.data;
const summaryReportPlatformsErrorSelector = (state: RootState) => state.admin.summary_report_v2.platforms.error;
const summaryReportPlatformsFetchingSelector = (state: RootState) => state.admin.summary_report_v2.platforms.isFetching;

const summaryReportColumnsDataSelector = (state: RootState) => state.admin.summary_report_v2.columns.data;
const summaryReportColumnsErrorSelector = (state: RootState) => state.admin.summary_report_v2.columns.error;
const summaryReportColumnsFetchingSelector = (state: RootState) => state.admin.summary_report_v2.columns.isFetching;

//selectors
export const selectSummaryReport = createSelector(summaryReportSelector, (report) => report);

export const selectOptions = createSelector(summaryReportOptionsSelector, (options) => options);
export const selectOptionsData = createSelector(summaryReportOptionsDataSelector, (data) => data);
export const selectOptionsError = createSelector(summaryReportOptionsErrorSelector, (error) => error);
export const selectOptionsIsFetching = createSelector(summaryReportOptionsFetchingSelector, (isFetching) => isFetching);

//columns
export const selectColumnsData = createSelector(summaryReportColumnsDataSelector, (data) => data);
export const selectColumnsError = createSelector(summaryReportColumnsErrorSelector, (error) => error);
export const selectColumnsIsFetching = createSelector(summaryReportColumnsFetchingSelector, (isFetching) => isFetching);

export const selectList = createSelector(summaryReportListSelector, (list) => list);
export const selectListDataColumnsSort = createSelector(summaryReportListDataSelector, (data) => data?.columns.filter((el: any) => el.sort === true));
const columnsFloat = [
  'poker_rake_sum',
  'poker_bonus',
  'ngr_poker',
  'poker_revenue_share_profit',
  'pkm_casino_bet_sum',
  'pkm_casino_ggr',
  'pkm_casino_bonus',
  'ngr_pkm_casino',
  'pkm_casino_revenue_share_profit',
];
export const selectListData = createSelector(summaryReportListDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns.reduce((acc: any[], item: any) => {
      if (item.name === 'creative_group') {
        acc.push({
          name: "creative_id",
          sort: true,
          type: "string",
        });
        acc.push({
          name: "creative_name",
          sort: true,
          type: "shortString",
        });
      } else if (item.name === 'tracker_group') {
        acc.push({
          name: "tracker_id",
          sort: true,
          type: "string",
        });
        acc.push({
          name: "tracker_description",
          sort: true,
          type: "shortString",
        });
      } else if (item.name === 'affiliate_group') {
        acc.push({
          name: "affiliate_id",
          sort: true,
          type: "clickableUserId",
        });
        acc.push({
          name: "affiliate_name",
          sort: true,
          type: "shortUsername",
        });
      } else if (item.name === 'transaction_date_for_reports') {
        acc.push({ ...item, type: 'datetime' });
      } else if (item.name === 'chief_name') {
        acc.push({ ...item, type: 'shortString' });
      } else if (item.name === 'landing_url') {
        acc.push({ ...item, type: 'link' });
      } else if (columnsFloat.includes(item.name)) {
        acc.push({ ...item, type: 'float' });
      } else if ((item.name === "anid")) {
        acc.push({ ...item, type: 'mediumString' });
      } else {
        acc.push({ ...item })
      }
      return acc;
    }, []);

    return { ...data, columns: columns };
  } else {
    return data;
  }
});
export const selectListError = createSelector(summaryReportListErrorSelector, (error) => error);
export const selectListIsFetching = createSelector(summaryReportListFetchingSelector, (isFetching) => isFetching);

export const selectDownload = createSelector(summaryReportDownloadSelector, (data) => data);
export const selectDownloadData = createSelector(summaryReportDownloadDataSelector, (data) => data);
export const selectDownloadError = createSelector(summaryReportDownloadErrorSelector, (error) => error);
export const selectDownloadIsFetching = createSelector(summaryReportDownloadFetchingSelector, (isFetching) => isFetching);

export const selectAffiliates = createSelector(summaryReportAffiliatesSelector, (data) => data);
export const selectAffiliatesData = createSelector(summaryReportAffiliatesDataSelector, (data) => data);
export const selectAffiliatesError = createSelector(summaryReportAffiliatesErrorSelector, (error) => error);
export const selectAffiliatesIsFetching = createSelector(summaryReportAffiliatesFetchingSelector, (isFetching) => isFetching);

export const selectTrackers = createSelector(summaryReportTrackersSelector, (data) => data);
export const selectTrackersData = createSelector(summaryReportTrackersDataSelector, (data) => data);
export const selectTrackersError = createSelector(summaryReportTrackersErrorSelector, (error) => error);
export const selectTrackersIsFetching = createSelector(summaryReportTrackersFetchingSelector, (isFetching) => isFetching);

export const selectDeals = createSelector(summaryReportDealsSelector, (data) => data);
export const selectDealsData = createSelector(summaryReportDealsDataSelector, (data) => data);
export const selectDealsError = createSelector(summaryReportDealsErrorSelector, (error) => error);
export const selectDealsIsFetching = createSelector(summaryReportDealsFetchingSelector, (isFetching) => isFetching);

export const selectCreatives = createSelector(summaryReportCreativesSelector, (data) => data);
export const selectCreativesData = createSelector(summaryReportCreativesDataSelector, (data) => data);
export const selectCreativesError = createSelector(summaryReportCreativesErrorSelector, (error) => error);
export const selectCreativesIsFetching = createSelector(summaryReportCreativesFetchingSelector, (isFetching) => isFetching);

export const selectProducts = createSelector(summaryReportProductsSelector, (data) => data);
export const selectProductsData = createSelector(summaryReportProductsDataSelector, (data) => data);
export const selectProductsError = createSelector(summaryReportProductsErrorSelector, (error) => error);
export const selectProductsIsFetching = createSelector(summaryReportProductsFetchingSelector, (isFetching) => isFetching);

export const selectManagers = createSelector(summaryReportManagersSelector, (data) => data);
export const selectManagersData = createSelector(summaryReportManagersDataSelector, (data) => data);
export const selectManagersError = createSelector(summaryReportManagersErrorSelector, (error) => error);
export const selectManagersIsFetching = createSelector(summaryReportManagersFetchingSelector, (isFetching) => isFetching);

export const selectBrands = createSelector(summaryReportBrandsSelector, (data) => data);
export const selectBrandsData = createSelector(summaryReportBrandsDataSelector, (data) => data);
export const selectBrandsError = createSelector(summaryReportBrandsErrorSelector, (error) => error);
export const selectBrandsIsFetching = createSelector(summaryReportBrandsFetchingSelector, (isFetching) => isFetching);

export const selectCurrencies = createSelector(summaryReportCurrenciesSelector, (data) => data);
export const selectCurrenciesData = createSelector(summaryReportCurrenciesDataSelector, (data) => data);
export const selectCurrenciesError = createSelector(summaryReportCurrenciesErrorSelector, (error) => error);
export const selectCurrenciesIsFetching = createSelector(summaryReportCurrenciesFetchingSelector, (isFetching) => isFetching);

export const selectPlatforms = createSelector(summaryReportPlatformsSelector, (data) => data);
export const selectPlatformsData = createSelector(summaryReportPlatformsDataSelector, (data) => data);
export const selectPlatformsError = createSelector(summaryReportPlatformsErrorSelector, (error) => error);
export const selectPlatformsIsFetching = createSelector(summaryReportPlatformsFetchingSelector, (isFetching) => isFetching);
