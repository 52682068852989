//core
import React from "react";
import {
  Box,
  Paper,
  Typography,
} from '@mui/material';

//styles
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import {OverridableStringUnion} from "@mui/types";
import {PaperPropsVariantOverrides} from "@mui/material/Paper/Paper";

export interface ITopWidgetItem {
  name: string;
  value: {
    main: number;
    comparison?: number;
  };
  /*[key: string]: {
    main: number;
    compare?: number;
  };*/
}

interface ICounterProps {
  data: ITopWidgetItem;
  className?: string;
  key?: string | number;
  variant?: OverridableStringUnion<'elevation' | 'outlined', PaperPropsVariantOverrides>;
}


const Counter: React.FC<ICounterProps> = ({
  data,
  variant,
  className,
}): JSX.Element => {
  const styles = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Box className={`${styles.block} ${!!className ? className : ''}`}>
      <Paper
        className={styles.paper}
        elevation={0}
        {...(!!variant ? { variant: variant } : {})}
      >
        <Box className={styles.header}>
          <Typography variant="overline">{t(`common.components.top_widgets.${data.name}`)}</Typography>
          {data.value.hasOwnProperty('comparison') && data.value.comparison ? (
            <Box
              className={`
                ${styles.comparison} ${data.value.comparison < data.value.main && 'success'} ${data.value.comparison > data.value.main && 'error'}
              `}
            >
              <Typography variant="subtitle2">
                {data.value.comparison < data.value.main && '+'}
                {new Intl.NumberFormat(`en`, {
                  maximumFractionDigits: 2,
                  notation: "compact" ,
                  compactDisplay: "short"
                }).format(((data.value.main/data.value.comparison) * 100) - 100)}%
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Typography className={styles.value} variant="h5">
          {new Intl.NumberFormat(i18n.language, {
            maximumFractionDigits: 2,
            //notation: "compact" ,
            //compactDisplay: "short"
          }).format(data.value.main)}
        </Typography>
      </Paper>
    </Box>
  );
};

export default Counter;
