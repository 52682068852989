//core
import { useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { createSelector } from "@reduxjs/toolkit";

//actions
import { configurationSliceState, getConfig, getPermission, getUploadConfig } from "./index";

export type stateConfiguration = {
  configuration: configurationSliceState
};

interface IRegFields {
  [key: string]: {
    check: boolean,
    id: string,
    value: string,
  }
}

const configSelector = (state: stateConfiguration) => state.configuration.config;
const configDataSelector = (state: stateConfiguration) => state.configuration.config.data;
const csrfTokenSelector = (state: stateConfiguration) => state.configuration.csrf_token;
const uploadConfigSelector = (state: stateConfiguration) => state.configuration.upload_config;
const uploadConfigDataSelector = (state: stateConfiguration) => state.configuration.upload_config.data;
const permissionSelector = (state: stateConfiguration) => state.configuration.permission;
const permissionDataSelector = (state: stateConfiguration) => state.configuration.permission.data;

const selectConfig = createSelector(
  configSelector,
  (config) => config
);
const selectConfigData = createSelector(
  configDataSelector,
  (data) => data
);
const selectCsrfToken = createSelector(
  csrfTokenSelector,
  (csrf_token) => csrf_token
);
export const selectUploadConfig = createSelector(
  uploadConfigSelector,
  (upload_config) => upload_config
);
const selectUploadConfigData = createSelector(
  uploadConfigDataSelector,
  (data) => data
);
const selectPermission = createSelector(
  permissionSelector,
  (permission) => permission
);
const selectPermissionData = createSelector(
  permissionDataSelector,
  (data) => data
);


export const useConfiguration = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const config = useSelector(selectConfig);
  const configData = useSelector(selectConfigData);
  const csrf_token = useSelector(selectCsrfToken);
  const upload_config = useSelector(selectUploadConfig);
  const permission = useSelector(selectPermission);

  const notInitialRender = useRef<boolean>(false);

  //get all configuration data
  useEffect(() => {
    if (notInitialRender.current) return;

    if (!configData && !config.isFetching) {
      dispatch(getConfig());
      notInitialRender.current = true;
    }
  }, [configData]);

  const getUploadConf = useCallback(
    () => dispatch(getUploadConfig()),
    [getUploadConfig],
  );

  const getUserPermission = useCallback(
    () => dispatch(getPermission()),
    [getPermission],
  );

  return {
    config,
    csrf_token,
    upload_config,
    permission,
    getUploadConf,
    getUserPermission,
  }
}
