//core
import i18n from "i18next";
//import Backend from "i18next-http-backend";
import Backend from "i18next-locize-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { REACT_APP_LANGUAGES } from "./constants/languages";

const availableBrandLanguages = JSON.parse(REACT_APP_LANGUAGES);

const locizeOptions = {
  projectId: '3878c62b-6eac-449d-93bb-55e691c78ed8',
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: (code) => {
      if (!REACT_APP_LANGUAGES.includes(code)) {
        localStorage.setItem('i18nextLng', availableBrandLanguages[0]);
        window.location.reload();
      }
      return availableBrandLanguages[0];
    },
    supportedLngs: availableBrandLanguages,
    detection: {
      lookupLocalStorage: 'i18nextLng',
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    interpolation: {
      escapeValue: false
    },
    backend: locizeOptions,
    /*backend: {
      queryStringParams: { v: `${packageJson.version}` }
    },*/
  });


export default i18n;
