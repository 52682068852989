//core
import React from "react";
import { useTranslation } from "react-i18next";
import { OverridableStringUnion } from "@mui/types";
import { PaperPropsVariantOverrides } from "@mui/material/Paper/Paper";
import { Theme } from "@mui/material/styles/createTheme";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  Typography
} from "@mui/material";

//icon
import { NoDataIcon } from "../../../../UI/Icons";

interface IEmptyTableProps {
  elevation?: number;
  transparent?: boolean;
  messageKey?: string;
  size?: 'sm' | 'md';
  variant?: OverridableStringUnion<'elevation' | 'outlined', PaperPropsVariantOverrides>;
  borderRadius?: number;
}

const EmptyTable: React.FC<IEmptyTableProps> = ({
  elevation = 2,
  transparent= false,
  messageKey = 'common.messages.no_data',
  size = 'md',
  variant = 'elevation',
  borderRadius = 0,
}): JSX.Element => {
  const theme: Theme = useTheme();
  const useStyles = makeStyles({
    card: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: size === 'md' ? "200px" : size === 'sm' ? "110px" : "200px",
      width: '100%',
      overflowX: 'auto',
      //borderRadius: '0',
      ...(transparent ? { backgroundColor: 'transparent' } : {}),
    },
    img: {
      width: '60px',
      height: '60px',
      margin: '0 0 12px',
      color: theme.palette.primary.main,
    },
    text: {

    },
  });
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Card
      className={styles.card}
      elevation={variant === 'outlined' ? 0 : elevation}
      variant={variant}
      sx={{ borderRadius: `${borderRadius}px` }}
    >
      <NoDataIcon className={styles.img} />
      <Typography variant="h6" component="div">
        {t(messageKey)}
      </Typography>
    </Card>
  );
};

export default EmptyTable;
