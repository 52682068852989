//core
import { createSelector } from "@reduxjs/toolkit";

//store
import {RootState} from "../../../index";

// state
const webhooksReportSelector = (state: RootState) => state.admin.webhooks_report;

const webhooksReportOptionsSelector = (state: RootState) => state.admin.webhooks_report.options;
const webhooksReportOptionsDataSelector = (state: RootState) => state.admin.webhooks_report.options.data;
const webhooksReportOptionsErrorSelector = (state: RootState) => state.admin.webhooks_report.options.error;
const webhooksReportOptionsFetchingSelector = (state: RootState) => state.admin.webhooks_report.options.isFetching;

const webhooksReportListSelector = (state: RootState) => state.admin.webhooks_report.list;
const webhooksReportListDataSelector = (state: RootState) => state.admin.webhooks_report.list.data;
const webhooksReportListErrorSelector = (state: RootState) => state.admin.webhooks_report.list.error;
const webhooksReportListFetchingSelector = (state: RootState) => state.admin.webhooks_report.list.isFetching;

const webhooksReportDownloadSelector = (state: RootState) => state.admin.webhooks_report.download;
const webhooksReportDownloadDataSelector = (state: RootState) => state.admin.webhooks_report.download.data;
const webhooksReportDownloadErrorSelector = (state: RootState) => state.admin.webhooks_report.download.error;
const webhooksReportDownloadFetchingSelector = (state: RootState) => state.admin.webhooks_report.download.isFetching;

//Filters
const filtersDataSelector = (state: RootState) => state.admin.webhooks_report.filters.data;
const filtersIsFetchingSelector = (state: RootState) => state.admin.webhooks_report.filters.isFetching;
const filtersErrorSelector = (state: RootState) => state.admin.webhooks_report.filters.error;


const webhooksReportDetailsDataSelector = (state: RootState) => state.admin.webhooks_report.details_webhook.data;
const webhooksReportDetailsErrorSelector = (state: RootState) => state.admin.webhooks_report.details_webhook.error;
const webhooksReportDetailsFetchingSelector = (state: RootState) => state.admin.webhooks_report.details_webhook.isFetching;


const webhooksReportColumnsDataSelector = (state: RootState) => state.admin.webhooks_report.columns.data;
const webhooksReportColumnsErrorSelector = (state: RootState) => state.admin.webhooks_report.columns.error;
const webhooksReportColumnsFetchingSelector = (state: RootState) => state.admin.webhooks_report.columns.isFetching;

//selectors
export const selectWebhooksReport = createSelector(webhooksReportSelector, (report) => report);

export const selectOptions = createSelector(webhooksReportOptionsSelector, (options) => options);
export const selectOptionsData = createSelector(webhooksReportOptionsDataSelector, (data) => data);
export const selectOptionsError = createSelector(webhooksReportOptionsErrorSelector, (error) => error);
export const selectOptionsIsFetching = createSelector(webhooksReportOptionsFetchingSelector, (isFetching) => isFetching);

export const selectList = createSelector(webhooksReportListSelector, (list) => list);
export const selectListData = createSelector(webhooksReportListDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns_for_display_data.map((item: any) => {
      if (item.name === 'id') {
        return { ...item, type: 'string' };
      }
      if (item.name === 'webhook_id') {
        return { ...item, type: 'string' };
      }
      if (item.name === 'player_id') {
        return { ...item, type: 'text' };
      }
      if (item.name === 'last_retry_at') {
        return { ...item, type: 'fulldatetime' };
      }
      if (item.name === 'created_at') {
        return { ...item, type: 'fulldatetime' };
      }
      if (item.name === 'tracker_id') {
        return { ...item, type: 'text' };
      }
      if (item.name === 'url') {
        return { ...item, type: 'link' };
      }
      if (item.name === 'payload') {
        return { ...item, type: 'webhookPayload' };
      }
      if (item.name === 'an_id') {
        return { ...item, type: 'mediumString' };
      }

      return item;
    });
    return { ...data, columns_for_display_data: columns };
  } else {
    return data;
  }
});
export const selectListError = createSelector(webhooksReportListErrorSelector, (error) => error);
export const selectListIsFetching = createSelector(webhooksReportListFetchingSelector, (isFetching) => isFetching);
export const selectListDataColumnsSort = createSelector(webhooksReportListDataSelector, (data) => data?.columns_for_display_data.filter((el: any) => el.sort === true));

export const selectDownload = createSelector(webhooksReportDownloadSelector, (data) => data);
export const selectDownloadData = createSelector(webhooksReportDownloadDataSelector, (data) => data);
export const selectDownloadError = createSelector(webhooksReportDownloadErrorSelector, (error) => error);
export const selectDownloadIsFetching = createSelector(webhooksReportDownloadFetchingSelector, (isFetching) => isFetching);


export const selectFiltersData = createSelector(filtersDataSelector, (data) => data);
export const selectIsFetchingFilters = createSelector(filtersIsFetchingSelector, (isFetching) => isFetching);
export const selectErrorFilters = createSelector(filtersErrorSelector, (error) => error);

export const selectDetailsWebhook = createSelector(webhooksReportDetailsDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns_for_display_data.map((item: any) => {
      if (item.name === 'url') {
        return { ...item, type: 'link' };
      }

      if (item.name === 'response_header') {
        return { ...item, type: 'trackerName' };
      }

      if (item.name === 'response_body') {
        return { ...item, type: 'trackerName' };
      }

      if (item.name === 'created_at') {
        return { ...item, type: 'fulldatetime' };
      }

      return item;
    });

    return { ...data, columns_for_display_data: columns };
  } else {
    return data;
  }
});
export const selectDetailsWebhookError = createSelector(webhooksReportDetailsErrorSelector, (error) => error);
export const selectDetailsWebhookIsFetching = createSelector(webhooksReportDetailsFetchingSelector, (isFetching) => isFetching);


export const selectColumnsData = createSelector(webhooksReportColumnsDataSelector, (data) => data);
export const selectColumnsError = createSelector(webhooksReportColumnsErrorSelector, (error) => error);
export const selectColumnsIsFetching = createSelector(webhooksReportColumnsFetchingSelector, (isFetching) => isFetching);
