//import arePropsEqual from "react-fast-compare";

interface Obj {
  [key: string]: any;
}


export const countValuesInObj = (obj: Obj): number => {
  const arr = Object.keys(obj);

  const count = arr.reduce((acc, item): number => {

    if (Array.isArray(obj[item]) && obj[item].length !== 0 || (obj[item] != '') && !(obj[item] === null)) {
      acc += 1;
    }

    return acc;
  }, 0)


  return count;
}

export const countValuesInObjWithExceptions = (obj: Obj, exceptions: string[]): number => {
  const arr = Object.keys(obj);

  const count = arr.reduce((acc, item): number => {
    if (!exceptions.includes(item)) {
      if (Array.isArray(obj[item]) && obj[item].length !== 0 || (obj[item] != '') && !(obj[item] === null)) {
        if (item === 'group_by') {
          if (Array.isArray(obj[item]['fields']) && obj[item]['fields'].length !== 0) {
            acc += 1;
          }
        } else {
          acc += 1;
        }
      }
    }

    return acc;
  }, 0)


  return count;
}
