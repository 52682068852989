//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";

//phone masks
import { defaultMasks } from "../../../constants/default-masks";
import {
  IDefaultCountries,
  defaultCountriesPreparation,
  defaultCountryCodeByBrand
} from "../../../helpers/phoneUtils";
import { BRAND } from "../../../api";

//service
import { gaEvents } from "../../../helpers/gaEvents";
import { registrationV2Service } from "../../../services/common/registrationV2.service";

export interface ITranslationsItem {
  [key: string]: any;
}

export interface IRegFieldOptionsItem {
  id: string,
  value: string,
  name?: string;
  isExtraFieldAllowed: boolean;
  isExtraFieldRequired: boolean;
  translations: ITranslationsItem | null;
}

export interface IRegFieldItem {
  name: string;
  type: string;
  defaultValue: any | null | '';
  isRequired: boolean;
  translations: ITranslationsItem | null;
  options?: IRegFieldOptionsItem[] | null;
}

export type registrationSliceState = {
  masks: {
    data: IDefaultCountries
  },
  country: { code: string },
  fields: {
    data: IRegFieldItem[] | null,
    error: any,
    isFetching: boolean,
  },
  regData: {
    data: any,
    error: any,
    isFetching: boolean,
  },

}

const initialState: registrationSliceState = {
  masks: {
    data: defaultCountriesPreparation(defaultMasks.countries)
  },
  country: defaultCountryCodeByBrand(`${BRAND}`),
  fields: {
    data: null,
    error: null,
    isFetching: false,
  },
  regData: {
    data: null,
    error: null,
    isFetching: false,
  },
}


export const getRegistrationFields: any = createAsyncThunk(
  'registrationV2/getRegistrationFields',
  async (_, { rejectWithValue }) => {
    try {
      const response = await registrationV2Service.getRegFields();
      const data: any = await response.json();
      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data.fields;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const registrationUser: any = createAsyncThunk(
  'registrationV2/registration',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await registrationV2Service.registration(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      gaEvents.setSignUp();

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);



//slice
const registrationV2Slice = createSlice({
  name: 'registrationV2',
  initialState: initialState,
  reducers: {
    setRegistrationError(state, action) {
      state.regData.error = action.payload;
    },
    setRegistrationData(state, action) {
      state.regData.data = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<registrationSliceState>>) => {
    builder.addCase(getRegistrationFields.pending, (state ) => {
      state.fields.isFetching = true;
      state.fields.error = null;
    });
    builder.addCase(getRegistrationFields.fulfilled, (state, action ) => {
      state.fields.error = null;
      state.fields.isFetching = false;
      state.fields.data = action.payload;
    });
    builder.addCase(getRegistrationFields.rejected, (state, action ) => {
      state.fields.error = action.payload;
    });


    builder.addCase(registrationUser.pending, (state ) => {
      state.regData.isFetching = true;
      state.regData.error = null;
    });
    builder.addCase(registrationUser.fulfilled, (state, action ) => {
      state.regData.error = null;
      state.regData.isFetching = false;
      state.regData.data = action.payload;
    });
    builder.addCase(registrationUser.rejected, (state, action ) => {
      state.regData.error = action.payload;
      state.regData.isFetching = false;
    });


  },
});

export default registrationV2Slice.reducer;

export const {
  setRegistrationError,
  setRegistrationData,
} = registrationV2Slice.actions;
