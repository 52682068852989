//core
import React, { useContext, useState, useEffect, useCallback } from "react";
import arePropsEqual from "react-fast-compare";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox, Tooltip, useMediaQuery,
} from "@mui/material";

//context
import {
  DetailsContext,
} from "../../../../admin/Reports/Finance_report/components/Details/DetailsContext";

//types
import { itemListData } from "../../index";
import {useSelector} from "react-redux";
import {selectListData} from "../../../../../store/admin/reports/details_finance_report/selectors";
import {useUI} from "../../../../../store/common/ui/useUI";
import {useTranslation} from "react-i18next";
import routes from "../../../../../navigation/routes";
import {useLocation} from "react-router-dom";

interface IText {
  value: string | number;
}

const useStyles = makeStyles({
  text: {
    color: "inherit",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",

    '&:hover': {
      textDecoration: "underline",
    }
  }
});

const ClickableUserId = ({
  value = '',
}: IText) => {
  const styles = useStyles();

  const [link, setLink] = useState<string>('');

  useEffect(() => {
    setLink(routes.admin.partners.account.main.replace(':affId', `${value}`))
  }, [value]);

  return (
    <>
      {value
        ? <Tooltip title={value}>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className={`${styles.text}`}
          >
            { value }
          </a>
        </Tooltip>
        : '—'}
    </>
  );
};

export default React.memo(ClickableUserId, arePropsEqual);
