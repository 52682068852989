import { setCookie } from "../helpers/utils";
import { cookieToken } from "../constants";
import { BRAND } from "../api";

export interface ILogin {
    authenticity_token: string | null,
    user: {
        email: string,
        password: string
        _remember_me: boolean;
    }
}

export interface IRequestOptions {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  credentials?: 'include',
  headers?: {
    'Content-Type': string,
    'accept': string,
    'X-Requested-With'?: string,
    'ap-brand'?: string,
    'auth-code'?: string,
    'auth-type'?: string,
  },
  body?: any
}

export const RequestOptions = (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  body?: string,
  headersProps?: {[key: string]: string},
): IRequestOptions => {
  return {
    method: method,
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
      "accept": "application/json",
      "ap-brand": BRAND,
      ...headersProps
      //"X-Requested-With": "XMLHttpRequest",
    },
    ...(!!body ? { body: body } : {}),
  }
}

export const handleResponse = (response: any) => {
  const xsrf_token = response.headers.get('xsrf-token');
  const status = response.status;

  //maintenance mode logic
  if (!window.location.pathname.includes('maintenance')) {
    if (status === 503) {
      response.json().then((data: any) => {
        if (data.hasOwnProperty('error') && data.error.toLowerCase() === 'maintenance') {
          window.location.href = `${window.location.origin}/maintenance`;
        }
      }).catch((error: any) => {
        console.log('error: ', error);
      });
    }
  }

  if (!!xsrf_token) {
    if (!xsrf_token.indexOf('{"')) {
      setCookie(cookieToken, Object.keys(JSON.parse(xsrf_token))[0]);
    } else {
      setCookie(cookieToken, xsrf_token);
    }
  }

  return Promise.resolve(response)
}
