//core
import React, { useContext, useState, useEffect, useCallback } from "react";
import arePropsEqual from "react-fast-compare";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox, useMediaQuery,
} from "@mui/material";

//context
import {
  DetailsContext,
} from "../../../../admin/Reports/Finance_report/components/Details/DetailsContext";

//types
import { itemListData } from "../../index";
import { ISelectedRow } from "../../../../../store/admin/reports/details_finance_report";
import {useSelector} from "react-redux";
import {selectListData} from "../../../../../store/admin/reports/details_finance_report/selectors";
import {useUI} from "../../../../../store/common/ui/useUI";
import {useTranslation} from "react-i18next";
import ClickableUserId from "../ClickableUserId";

interface IText {
  value: string | number;
  data: itemListData;
  setSelectedRow: (state: boolean) => void;
}

const useStyles = makeStyles({
  block: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text: {
    color: 'inherit',
  }
});

const SelectedFinReportRow = ({
  value = '',
  data,
  setSelectedRow,
}: IText) => {
  const styles = useStyles();
  const context = useContext(DetailsContext);
  const tableData = useSelector(selectListData);
  const mobile = useMediaQuery('(max-width:767px)');
  const { t } = useTranslation();

  const { setGeneralSnackbar } = useUI();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!!context.selectedRows.length) {
      context.selectedRows.map((item) => {
        if (item.affiliateId === data.affiliateId
          && item.brand === data.brand
          && item.currency === data.currency) {
          setChecked(true);
        }
      });
    } else {
      setChecked(false);
    }
  }, [context.selectedRows]);

  useEffect(() => {
    if (!!context.selectedRows.length) {
      const filtered:itemListData = context.selectedRows.reduce((acc:any, item:any) => {
        tableData.list.forEach((row:any) => {
          if (row.affiliateId === item.affiliateId
              && row.brand === item.brand
              && row.currency === item.currency) {
            acc.push(row)
          }
        });
        return acc;
      }, []);
      //@ts-ignore
      context.setSelectedRows(filtered);
    }
  },[tableData])

  useEffect(() => {
    if (checked) {
      setSelectedRow(true);
    } else {
      setSelectedRow(false);
    }
  }, [checked]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const selected: itemListData = data;

    if (event.target.checked) {
      context.addSelectedRow(selected);

      if (selected.prepayment < 0) {
        setGeneralSnackbar({
          open: true,
          type: 'warning',
          message: t("common.messages.negative_prepayment"),
        })

        return;
      }
      if (selected.adjustmentsToApprove !== 0) {
        setGeneralSnackbar({
          open: true,
          type: 'warning',
          message: t("common.messages.not_approved_adjustment"),
        })
      }
    } else {
      const filtered = context.selectedRows.filter(item => {
        return item.affiliateId !== selected.affiliateId || item.brand !== selected.brand || item.currency !== selected.currency
      });
      context.setSelectedRows(filtered)
    }

    setChecked(event.target.checked);
  }, [data, setChecked, context.selectedRows]);

  return (
    <Box
      component="span"
      className={styles.block}
    >
      <Checkbox
        size="small"
        sx={{ padding: '4px' }}
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <span className={styles.text}>
        {mobile && 'ID '}
        {value ? <ClickableUserId value={value} /> : '—'}
        {mobile && !!data.affiliateUsername && ` - ${data.affiliateUsername}`}
      </span>
    </Box>
  );
};

export default React.memo(SelectedFinReportRow, arePropsEqual);
