import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { cookieToken } from "../../constants";



const getRegFields = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.registrationsV2.getFields}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`;

  return fetch(
    `${endpoint}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const registration = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  //const
  const endpoint = api.registrationsV2.registration;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


export const registrationV2Service = {
  getRegFields,
  registration,
}
