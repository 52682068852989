//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { setUser } from "../../common/user";
import { setGeneralProgressHide, setGeneralProgressShow, setGeneralSnackbarState } from "../../common/ui";
import { affProfileService } from "../../../services/admin/affProfile.service";
import { PaymentItemType } from "../../affiliate/profile";
import {profileService} from "../../../services/affiliate/profile.service";

export type CommunicationCreatePayloadType = {
  affiliateId: string | number;
  data: {
    status: string;
    date: string | null;
    notes?: string;
  },
};

export type CommunicationUpdatePayloadType = {
  affiliateId: string | number;
  communicationId: string | number;
  data: {
    status: string;
    date: string | null;
    notes: string;
  },
};

export type TrafficStatusUpdatePayloadType = {
  affiliateId: string | number;
  data: {
    traffic_status: string;
  }
};

export type OrderType = {
  [key: string]: 'asc' | 'desc'
};

export interface ProfilePayloadType {
  affiliateId: string | number;
}

export type TrafficSourcesItem = {
  id: number;
  extra_info: string | null;
}

export type TrafficGeoItem = {
  country_code: string;
}

export interface UpdateProfileType {
  profile?: {
    username?: string;
    name?: string;
    surname?: string;
    birthday?: string | null;
    chief_id?: number;
    notes?: string;
    roleId?: number;
  },
  contact?: {
    type: string;
    value: string;
  },
  traffic?: {
    status: string | null;
    sources: TrafficSourcesItem[];
    geo: TrafficGeoItem[];
  },
  accounting_details?: {
    contract_signed: boolean;
    accounting_number: string;
  },
}

export interface UpdateProfilePayloadType {
  affiliateId: string | number;
  data: UpdateProfileType;
}

export interface EditEmailPayloadType {
  affiliateId: string | number;
  auth_type?: string;
  auth_code?: string;
  data: {
    email: string;
  };
}

export interface ChiefsPayloadDataType {
  chief_id: number;
  additional_chief_ids: number[];
}

export interface UpdateProfileChiefsPayloadType {
  affiliateId: string | number;
  data: ChiefsPayloadDataType;
}

export type ProfileFieldStringType = {
  value: string;
  is_disable: boolean;
}

export type ProfileFieldDateType = {
  value: string | null;
  is_disable: boolean;
}

export type ProfileFieldBooleanType = {
  value: boolean;
  is_disable: boolean;
}

export type ChiefItem = {
  id: number;
  name: string;
}

export type ProfileFieldChiefType = {
  value: ChiefItem;
  is_disable: boolean;
}

export type RoleItem = {
  id: number;
  name: string;
}

export type ProfileFieldRoleType = {
  value: number;
  options: RoleItem[];
  is_disable: boolean;
}

export interface ProfileType {
  affiliate: {
    profile: {
      additional_chiefs: {
        value: ChiefItem[];
      },
      approve_date: ProfileFieldStringType;
      birthday: ProfileFieldDateType;
      chief: ProfileFieldChiefType;
      is_blocked: ProfileFieldBooleanType;
      name: ProfileFieldStringType;
      notes: ProfileFieldStringType;
      registration_date: ProfileFieldDateType;
      role: ProfileFieldRoleType;
      status: ProfileFieldStringType;
      surname: ProfileFieldStringType;
      username: ProfileFieldStringType;
      platform_id: ProfileFieldStringType;
    },
    contact: {
      email: ProfileFieldStringType;
      phone: ProfileFieldStringType;
      type: ProfileFieldStringType;
      value: ProfileFieldStringType;
    },
    traffic: {
      status: ProfileFieldStringType;
      source: {
        value: TrafficSourcesItem[];
        is_disable: boolean;
      };
      geo: {
        value: TrafficGeoItem[];
        is_disable: boolean;
      };
    },
    sub_affiliate: {
      date: {
        value: string | null;
        is_disable: boolean;
      },
      master: {
        value: string | null;
        is_disable: boolean;
      },
    },
    chiefs: {
      additional_chiefs: {
        value: ChiefItem[];
        is_disable: boolean;
      },
      chief: {
        value: ChiefItem;
        is_disable: boolean;
      }
    } | null,
  }
}

export interface CommunicationHistoryPayloadType {
  affiliateId: string | number;
  data: {
    page: number;
    per_page: number;
    order: OrderType;
  }
}

export interface CommunicationDeletePayloadType {
  affiliateId: string | number;
  communicationId: string | number;
}

export type HistoryListUser = {
  id: number;
  username: string;
}

export type HistoryListItem = {
  id: number;
  affiliate_id: number;
  communication_status: string;
  communication_date: string;
  updated_at: string;
  created_by: HistoryListUser;
  updated_by: HistoryListUser;
  notes: string;
};

export type HistoryColumnsItem = {
  name: string;
  sort: boolean;
  type: string;
}

export type HistoryListType = {
  list: HistoryListItem[],
  columns: HistoryColumnsItem[],
  total_entries: number;
  start_row_num: number;
  end_row_num: number;
  total_pages: number;
}



export type AffiliateProfileSliceState = {
  communicationHistory: {
    data: HistoryListType | null,
    error: any,
    isFetching: boolean,
  },
  communicationCreate: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  communicationDelete: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  communicationUpdate: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  trafficStatusUpdate: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  profile: {
    data: ProfileType | null,
    error: any,
    isFetching: boolean,
  },
  updateProfile: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  updateChiefs: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  paymentList: {
    data: PaymentItemType[] | null,
    error: any,
    isFetching: boolean,
  },
  paymentMethods: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  createPaymentMethod: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  deletePaymentMethod: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  updatePaymentMethod: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  paymentHistory: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  editEmail: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  additionalInfo: {
    data: any,
    error: any,
    isFetching: boolean,
  }
};

const initialState: AffiliateProfileSliceState = {
  communicationHistory: {
    data: null,
    error: null,
    isFetching: false,
  },
  communicationCreate: {
    data: null,
    error: null,
    isFetching: false,
  },
  communicationDelete: {
    data: null,
    error: null,
    isFetching: false,
  },
  communicationUpdate: {
    data: null,
    error: null,
    isFetching: false,
  },
  trafficStatusUpdate: {
    data: null,
    error: null,
    isFetching: false,
  },
  profile: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateProfile: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateChiefs: {
    data: null,
    error: null,
    isFetching: false,
  },
  paymentList: {
    data: null,
    error: null,
    isFetching: false,
  },
  paymentMethods: {
    data: null,
    error: null,
    isFetching: false,
  },
  createPaymentMethod: {
    data: null,
    error: null,
    isFetching: false,
  },
  deletePaymentMethod: {
    data: null,
    error: null,
    isFetching: false,
  },
  updatePaymentMethod: {
    data: null,
    error: null,
    isFetching: false,
  },
  paymentHistory: {
    data: null,
    error: null,
    isFetching: false,
  },
  editEmail: {
    data: null,
    error: null,
    isFetching: false,
  },
  additionalInfo: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const setCreateCommunicationStatus: any = createAsyncThunk(
  'affiliateProfile/createCommunicationStatus',
  async (payload: CommunicationCreatePayloadType, { rejectWithValue, dispatch }) => {
    try {
      const response = await affProfileService.createCommunicationStatus(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const setDeleteCommunication: any = createAsyncThunk(
  'affiliateProfile/setDeleteCommunication',
  async (payload: CommunicationDeletePayloadType, { rejectWithValue, dispatch }) => {
    try {
      const response = await affProfileService.deleteCommunicationStatus(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const getCommunicationHistoryList: any = createAsyncThunk(
  'affiliateProfile/getCommunicationHistoryList',
  async (payload: CommunicationHistoryPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affProfileService.getCommunicationHistory(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setUpdateCommunicationStatus: any = createAsyncThunk(
  'affiliateProfile/setUpdateCommunicationStatus',
  async (payload: CommunicationUpdatePayloadType, { rejectWithValue, dispatch }) => {
    try {
      const response = await affProfileService.updateCommunicationStatus(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const setTrafficStatusUpdate: any = createAsyncThunk(
  'affiliateProfile/setTrafficStatusUpdate',
  async (payload: TrafficStatusUpdatePayloadType, { rejectWithValue, dispatch }) => {
    try {
      const response = await affProfileService.updateTrafficStatus(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const getProfileData: any = createAsyncThunk(
  'affiliateProfile/getProfile',
  async (payload: ProfilePayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affProfileService.getProfile(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.affiliate_not_found',
          })
        );
        return rejectWithValue({
          status: response.status,
          statusText: response.statusText,
        })
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const updateProfileData: any = createAsyncThunk(
  'affiliateProfile/updateProfileData',
  async (payload: UpdateProfilePayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affProfileService.updateProfile(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const updateChiefsData: any = createAsyncThunk(
  'affiliateProfile/updateChiefsData',
  async (payload: UpdateProfileChiefsPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affProfileService.updateChiefs(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const getPaymentListData: any = createAsyncThunk(
  'affiliateProfile/getPaymentListData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affProfileService.getPaymentList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getPaymentMethodsData: any = createAsyncThunk(
  'affiliateProfile/getPaymentMethodsData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affProfileService.getPaymentMethods(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const createPaymentMethodData: any = createAsyncThunk(
  'affiliateProfile/createPaymentMethodData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affProfileService.postPaymentMethod(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 201) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.created',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const deletePaymentMethodData: any = createAsyncThunk(
  'affiliateProfile/deletePaymentMethodData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affProfileService.deletePaymentMethod(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.removed',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const updatePaymentMethodData: any = createAsyncThunk(
  'affiliateProfile/updatePaymentMethodData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affProfileService.updatePaymentMethod(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.updated',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getPaymentHistoryData: any = createAsyncThunk(
  'affiliateProfile/getPaymentHistoryData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affProfileService.getPaymentHistory(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const editEmailData: any = createAsyncThunk(
  'affiliateProfile/editEmailData',
  async (payload: EditEmailPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affProfileService.editEmail(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());

        if (data.error === 'affiliate.change_email.unable_to_update_email') {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'warning',
              message: 'error',
              messageKey: 'common.messages.unable_to_update_email',
            })
          );
        }

        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const getAdditionalInfoData: any = createAsyncThunk(
  'affiliateProfile/getAdditionalInfoData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await affProfileService.getAdditionalInfo(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.affiliate_not_found',
          })
        );
        return rejectWithValue({
          status: response.status,
          statusText: response.statusText,
        })
      }

      dispatch(setGeneralProgressHide());
      return !!data.fields ? data.fields : {};
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

//slice
const affiliateProfileSlice = createSlice({
  name: 'affiliateProfile',
  initialState: initialState,
  reducers: {
    clearCommunicationCreateState(state) {
      state.communicationCreate.data = null;
      state.communicationCreate.error = null;
      state.communicationCreate.isFetching = false;
    },
    clearCommunicationDeleteState(state) {
      state.communicationDelete.data = null;
      state.communicationDelete.error = null;
      state.communicationDelete.isFetching = false;
    },
    clearCommunicationUpdateState(state) {
      state.communicationUpdate.data = null;
      state.communicationUpdate.error = null;
      state.communicationUpdate.isFetching = false;
    },
    clearTrafficStatusUpdateState(state) {
      state.trafficStatusUpdate.data = null;
      state.trafficStatusUpdate.error = null;
      state.trafficStatusUpdate.isFetching = false;
    },
    clearProfileState(state) {
      state.profile.data = null;
      state.profile.error = null;
      state.profile.isFetching = false;
    },
    clearUpdateProfileState(state) {
      state.updateProfile.data = null;
      state.updateProfile.error = null;
      state.updateProfile.isFetching = false;
    },
    clearUpdateChiefsState(state) {
      state.updateProfile.data = null;
      state.updateProfile.error = null;
      state.updateProfile.isFetching = false;
    },
    clearCommunicationHistoryState(state) {
      state.communicationHistory.data = null;
      state.communicationHistory.error = null;
      state.communicationHistory.isFetching = false;
    },
    clearPaymentListState(state) {
      state.paymentList.data = null;
      state.paymentList.error = null;
      state.paymentList.isFetching = false;
    },
    clearPaymentMethodsState(state) {
      state.paymentMethods.data = null;
      state.paymentMethods.error = null;
      state.paymentMethods.isFetching = false;
    },
    clearCreatePaymentMethodState(state) {
      state.createPaymentMethod.data = null;
      state.createPaymentMethod.error = null;
      state.createPaymentMethod.isFetching = false;
    },
    clearDeletePaymentMethodState(state) {
      state.deletePaymentMethod.data = null;
      state.deletePaymentMethod.error = null;
      state.deletePaymentMethod.isFetching = false;
    },
    clearUpdatePaymentMethodState(state) {
      state.updatePaymentMethod.data = null;
      state.updatePaymentMethod.error = null;
      state.updatePaymentMethod.isFetching = false;
    },
    clearPaymentHistoryState(state) {
      state.paymentHistory.data = null;
      state.paymentHistory.error = null;
      state.paymentHistory.isFetching = false;
    },
    clearEditEmailState(state) {
      state.editEmail.data = null;
      state.editEmail.error = null;
      state.editEmail.isFetching = false;
    },
    clearAdditionalInfoState(state) {
      state.additionalInfo.data = null;
      state.additionalInfo.error = null;
      state.additionalInfo.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<AffiliateProfileSliceState>>) => {
    builder.addCase(setCreateCommunicationStatus.pending, (state ) => {
      state.communicationCreate.isFetching = true;
      state.communicationCreate.error = null;
    });
    builder.addCase(setCreateCommunicationStatus.fulfilled, (state , action) => {
      state.communicationCreate.error = null;
      state.communicationCreate.isFetching = false;
      state.communicationCreate.data = action.payload;
    });
    builder.addCase(setCreateCommunicationStatus.rejected, (state , action) => {
      state.communicationCreate.isFetching = false;
      state.communicationCreate.error = action.payload;
    });

    builder.addCase(getCommunicationHistoryList.pending, (state ) => {
      state.communicationHistory.isFetching = true;
      state.communicationHistory.error = null;
    });
    builder.addCase(getCommunicationHistoryList.fulfilled, (state , action) => {
      state.communicationHistory.error = null;
      state.communicationHistory.isFetching = false;
      state.communicationHistory.data = action.payload;
    });
    builder.addCase(getCommunicationHistoryList.rejected, (state , action) => {
      state.communicationHistory.isFetching = false;
      state.communicationHistory.error = action.payload;
    });

    builder.addCase(setDeleteCommunication.pending, (state ) => {
      state.communicationDelete.isFetching = true;
      state.communicationDelete.error = null;
    });
    builder.addCase(setDeleteCommunication.fulfilled, (state , action) => {
      state.communicationDelete.error = null;
      state.communicationDelete.isFetching = false;
      state.communicationDelete.data = action.payload;
    });
    builder.addCase(setDeleteCommunication.rejected, (state , action) => {
      state.communicationDelete.isFetching = false;
      state.communicationDelete.error = action.payload;
    });

    builder.addCase(setUpdateCommunicationStatus.pending, (state ) => {
      state.communicationUpdate.isFetching = true;
      state.communicationUpdate.error = null;
    });
    builder.addCase(setUpdateCommunicationStatus.fulfilled, (state , action) => {
      state.communicationUpdate.error = null;
      state.communicationUpdate.isFetching = false;
      state.communicationUpdate.data = action.payload;
    });
    builder.addCase(setUpdateCommunicationStatus.rejected, (state , action) => {
      state.communicationUpdate.isFetching = false;
      state.communicationUpdate.error = action.payload;
    });

    builder.addCase(setTrafficStatusUpdate.pending, (state ) => {
      state.trafficStatusUpdate.isFetching = true;
      state.trafficStatusUpdate.error = null;
    });
    builder.addCase(setTrafficStatusUpdate.fulfilled, (state , action) => {
      state.trafficStatusUpdate.error = null;
      state.trafficStatusUpdate.isFetching = false;
      state.trafficStatusUpdate.data = action.payload;
    });
    builder.addCase(setTrafficStatusUpdate.rejected, (state , action) => {
      state.trafficStatusUpdate.isFetching = false;
      state.trafficStatusUpdate.error = action.payload;
    });

    builder.addCase(getProfileData.pending, (state ) => {
      state.profile.isFetching = true;
      state.profile.error = null;
    });
    builder.addCase(getProfileData.fulfilled, (state , action) => {
      state.profile.error = null;
      state.profile.isFetching = false;
      state.profile.data = action.payload;
    });
    builder.addCase(getProfileData.rejected, (state , action) => {
      state.profile.isFetching = false;
      state.profile.error = action.payload;
    });

    builder.addCase(updateProfileData.pending, (state ) => {
      state.updateProfile.isFetching = true;
      state.updateProfile.error = null;
    });
    builder.addCase(updateProfileData.fulfilled, (state , action) => {
      state.updateProfile.error = null;
      state.updateProfile.isFetching = false;
      state.updateProfile.data = action.payload;
    });
    builder.addCase(updateProfileData.rejected, (state , action) => {
      state.updateProfile.isFetching = false;
      state.updateProfile.error = action.payload;
    });

    builder.addCase(updateChiefsData.pending, (state ) => {
      state.updateChiefs.isFetching = true;
      state.updateChiefs.error = null;
    });
    builder.addCase(updateChiefsData.fulfilled, (state , action) => {
      state.updateChiefs.error = null;
      state.updateChiefs.isFetching = false;
      state.updateChiefs.data = action.payload;
    });
    builder.addCase(updateChiefsData.rejected, (state , action) => {
      state.updateChiefs.isFetching = false;
      state.updateChiefs.error = action.payload;
    });

    //getPaymentListData
    builder.addCase(getPaymentListData.pending, (state ) => {
      state.paymentList.isFetching = true;
      state.paymentList.error = null;
    });
    builder.addCase(getPaymentListData.fulfilled, (state , action) => {
      state.paymentList.error = null;
      state.paymentList.isFetching = false;
      state.paymentList.data = action.payload.list;
    });
    builder.addCase(getPaymentListData.rejected, (state , action) => {
      state.paymentList.isFetching = false;
      state.paymentList.error = action.payload;
    });

    builder.addCase(getPaymentMethodsData.pending, (state ) => {
      state.paymentMethods.isFetching = true;
      state.paymentMethods.error = null;
    });
    builder.addCase(getPaymentMethodsData.fulfilled, (state , action) => {
      state.paymentMethods.error = null;
      state.paymentMethods.isFetching = false;
      state.paymentMethods.data = action.payload;
    });
    builder.addCase(getPaymentMethodsData.rejected, (state , action) => {
      state.paymentMethods.isFetching = false;
      state.paymentMethods.error = action.payload;
    });

    builder.addCase(createPaymentMethodData.pending, (state ) => {
      state.createPaymentMethod.isFetching = true;
      state.createPaymentMethod.error = null;
    });
    builder.addCase(createPaymentMethodData.fulfilled, (state , action) => {
      state.createPaymentMethod.error = null;
      state.createPaymentMethod.isFetching = false;
      state.createPaymentMethod.data = action.payload;
    });
    builder.addCase(createPaymentMethodData.rejected, (state , action) => {
      state.createPaymentMethod.isFetching = false;
      state.createPaymentMethod.error = action.payload;
    });

    builder.addCase(deletePaymentMethodData.pending, (state ) => {
      state.deletePaymentMethod.isFetching = true;
      state.deletePaymentMethod.error = null;
    });
    builder.addCase(deletePaymentMethodData.fulfilled, (state , action) => {
      state.deletePaymentMethod.error = null;
      state.deletePaymentMethod.isFetching = false;
      state.deletePaymentMethod.data = action.payload;
    });
    builder.addCase(deletePaymentMethodData.rejected, (state , action) => {
      state.deletePaymentMethod.isFetching = false;
      state.deletePaymentMethod.error = action.payload;
    });

    builder.addCase(updatePaymentMethodData.pending, (state ) => {
      state.updatePaymentMethod.isFetching = true;
      state.updatePaymentMethod.error = null;
    });
    builder.addCase(updatePaymentMethodData.fulfilled, (state , action) => {
      state.updatePaymentMethod.error = null;
      state.updatePaymentMethod.isFetching = false;
      state.updatePaymentMethod.data = action.payload;
    });
    builder.addCase(updatePaymentMethodData.rejected, (state , action) => {
      state.updatePaymentMethod.isFetching = false;
      state.updatePaymentMethod.error = action.payload;
    });

    builder.addCase(getPaymentHistoryData.pending, (state ) => {
      state.paymentHistory.isFetching = true;
      state.paymentHistory.error = null;
    });
    builder.addCase(getPaymentHistoryData.fulfilled, (state , action) => {
      state.paymentHistory.error = null;
      state.paymentHistory.isFetching = false;
      state.paymentHistory.data = action.payload;
    });
    builder.addCase(getPaymentHistoryData.rejected, (state , action) => {
      state.paymentHistory.isFetching = false;
      state.paymentHistory.error = action.payload;
    });

    builder.addCase(editEmailData.pending, (state ) => {
      state.editEmail.isFetching = true;
      state.editEmail.error = null;
    });
    builder.addCase(editEmailData.fulfilled, (state , action) => {
      state.editEmail.error = null;
      state.editEmail.isFetching = false;
      state.editEmail.data = action.payload;
    });
    builder.addCase(editEmailData.rejected, (state , action) => {
      state.editEmail.isFetching = false;
      state.editEmail.error = action.payload;
    });

    builder.addCase(getAdditionalInfoData.pending, (state ) => {
      state.additionalInfo.isFetching = true;
      state.additionalInfo.error = null;
    });
    builder.addCase(getAdditionalInfoData.fulfilled, (state , action) => {
      state.additionalInfo.error = null;
      state.additionalInfo.isFetching = false;
      state.additionalInfo.data = action.payload;
    });
    builder.addCase(getAdditionalInfoData.rejected, (state , action) => {
      state.additionalInfo.isFetching = false;
      state.additionalInfo.error = action.payload;
    });
  },
});

export default affiliateProfileSlice.reducer;

export const {
  clearCommunicationCreateState,
  clearCommunicationDeleteState,
  clearCommunicationUpdateState,
  clearTrafficStatusUpdateState,
  clearProfileState,
  clearUpdateProfileState,
  clearUpdateChiefsState,
  clearCommunicationHistoryState,
  clearPaymentListState,
  clearPaymentMethodsState,
  clearCreatePaymentMethodState,
  clearDeletePaymentMethodState,
  clearUpdatePaymentMethodState,
  clearPaymentHistoryState,
  clearEditEmailState,
  clearAdditionalInfoState,
} = affiliateProfileSlice.actions;
