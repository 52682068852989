//core
import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  InputLabel,
  Tooltip,
  Typography
} from "@mui/material";

//styles
import { useStyles } from "./styles";

//components
import Integer from "../../../Integer";
import Text from "../../../Text";
import Boolean from "../../../Boolean";
import TooltipText from "../../../TooltipText";
import ArrayOfStrings from "../../../ArrayOfStrings";
import Name from "../../../Name";
import BadgeField from "../../../BadgeField";
import CheckedField from "../../../CheckedField";
import HideSwitcher from "../../../../../../admin/Creatives/components/TableActions/HideSwicher";
import HideSwitcherV2 from "../../../../../../admin/CreativesV2/components/TableActions/HideSwicherV2";
import DateTime from "../../../DateTime";
import DateTimeRange from "../../../DateTimeRange";
import FloatFormat from "../../../FloatFormat";
import DateRange from "../../../DateRange";
import Status from "../../../Status";
import FinReportStatus from "../../../FinReportStatus";
import PartnerStatus from "../../../PartnerStatus";
import Link from "../../../Link";
import ObjArray from "../../../ObjArray";
import CountriesArray from "../../../CountriesArray";
import TranslationText from "../../../TranslationText";
import Preview from "../../../Preview";
import Adjustment from "../../../Adjustment";
import Switcher from "../../../../../../affiliate/s2s/components/TableActions/switcher";
import EmailLink from "../../../EmailLink";
import Contact from "../../../Contact";
import Manager from "../../../Manager";
import DepartmentTeam from "../../../DepartmentTeam";
import CommunicationStatus from "../../../../../../admin/Partners/components/TableActions/CommunicationStatus";
import TrafficStatus from "../../../../../../admin/Partners/components/TableActions/TrafficStatus";
import CommunicationDate from "../../../../../../admin/Partners/components/TableActions/CommunicationDate";
import Condition from "../../../Condition";
import MasterAffiliate from "../../../MasterAffiliate";
import DetailFinReportRowComment
  from "../../../../../../admin/Reports/Finance_report/components/Details/components/Table/components/DetailFinReportRowComment";
import PaymentDescription from "../../../PaymentDescription";
import TextWithBtn from "../../../TextWithBtn";
import AdjustmentReason from "../../../AdjustmentReason";
import PaymentHistoryStatus from "../../../PaymentHistoryStatus";
import Wallet from "../../../Wallet";
import Currency from "../../../Currency";
import FloatOrNull from "../../../FloatOrNull";

//types
import {itemListData, itemColumnsData} from "../../../../index";
import {getSymbolFromCode} from "currency-code-symbol-map";
import SelectedFinReportRow from "../../../SelectedFinReportRow";
import AdjustmentStatus from "../../../AdjustmentStatus";
import LogsItem
  from "../../../../../../admin/Reports/Finance_report/components/Details/PaymentDetails/components/Logs/components/logsItem";
import AffPaymentDetails from "../../../AffPaymentDetails";
import AdmPaymentDetails from "../../../AdmPaymentDetails";
import ClickableUserId from "../../../ClickableUserId";

const getTooltipTitle = (str: string): any[] =>  {
  const splitted = str.split('\n');

  const result: any[] = [];
  splitted.forEach((element: string, index: number) => {
    result.push(element);
    if (index < splitted.length - 1) {
      result.push(<br/>);
    }
  });

  return result;
}

interface IDetailsInfoBlock {
  row: itemListData;
  column: itemColumnsData;
  translationColumnsPrefix: string;
  translationTooltipsPrefix?: string;
  inline?: boolean;
  columnCurrency?: any;
}

const DetailsInfoBlock: React.FC<IDetailsInfoBlock> = ({
  column,
  row,
  translationColumnsPrefix,
  translationTooltipsPrefix = '',
  inline = false,
  columnCurrency = null,
}): JSX.Element => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState(false);

  const getColumnTitle = (item: itemColumnsData) => {
    return (
      <>
        {item.hasOwnProperty('isRecalculatable') && item.isRecalculatable ? (
          <>
            {t(`${translationColumnsPrefix}${item.name}`).includes('{currency}')
              ? t(`${translationColumnsPrefix}${item.name}`).replace('{currency}', (!!columnCurrency && (!!getSymbolFromCode(columnCurrency.id.toUpperCase()) ? getSymbolFromCode(columnCurrency.id.toUpperCase()) : columnCurrency.id)) || '$')
              : t(`${translationColumnsPrefix}${item.name}`).includes('$')
                ? t(`${translationColumnsPrefix}${item.name}`).replace('$', (!!columnCurrency && (!!getSymbolFromCode(columnCurrency.id.toUpperCase()) ? getSymbolFromCode(columnCurrency.id.toUpperCase()) : columnCurrency.id)) || '$')
                : `${t(`${translationColumnsPrefix}${item.name}`)}, ${!!columnCurrency && (!!getSymbolFromCode(columnCurrency.id.toUpperCase()) ? getSymbolFromCode(columnCurrency.id.toUpperCase()) : columnCurrency.id) || '$'}`}
          </>
        ) : <>
          {t(`${translationColumnsPrefix}${item.name}`)}
        </>}
      </>
    )
  }

  const name = !!translationTooltipsPrefix ? <>
    {!!t(`${translationTooltipsPrefix}${column.name}`) ? (
      <Tooltip
        title={
          getTooltipTitle(t(`${translationTooltipsPrefix}${column.name}`).replace('{currency}', (!!columnCurrency && (!!getSymbolFromCode(columnCurrency.id.toUpperCase()) ? getSymbolFromCode(columnCurrency.id.toUpperCase()) : columnCurrency.id)) || '$'))
        }
        placement="bottom"
      >
        <span>
          {getColumnTitle(column)}
        </span>
      </Tooltip>
    ) : getColumnTitle(column)}
  </> : getColumnTitle(column);

  const fields = <>
    { column.type === "finAffId" && <SelectedFinReportRow setSelectedRow={setSelectedRow} data={row} value={row[column.name]} /> }
    { column.type === "integer" && <Integer value={row[column.name]} /> }
    { column.type === "text" && <Text value={row[column.name]} /> }
    { column.type === "boolean" && <Boolean value={row[column.name]} /> }
    { column.type === "string" && <Text value={row[column.name]} /> }
    { column.type === "trackerName" && <TooltipText value={row[column.name]} width={280} maxWidth={280} /> }
    { column.type === "webhookPayload" && <Text breakAll value={row[column.name]} /> }
    { column.type === "trackerDealName" && <TooltipText value={row[column.name]} width={250} maxWidth={250} /> }
    { column.type === "S2sName" && <TooltipText value={row[column.name]} width={340} maxWidth={340} /> }
    { column.type === "invitationLinksName" && <TooltipText value={row[column.name]} width={340} maxWidth={340} /> }
    { column.type === "S2sUrl" && <TooltipText value={row[column.name]} width={300} maxWidth={300} /> }
    { column.type === "S2sTrackers" && <ArrayOfStrings value={row[column.name]} maxWidth={200} /> }
    { column.type === "name" && <Name value={row[column.name]} /> }
    { column.type === "badge" && <BadgeField value={row[column.name]} /> }
    { column.type === "toggleCreativeHideField" && <HideSwitcher creative={row} value={row[column.name]} /> }
    { column.type === "toggleCreativeHideFieldV2" && <HideSwitcherV2 creative={row} value={row[column.name]} /> }
    { column.type === "date" && <DateTime value={row[column.name]} /> }
    { column.type === "datetime" && <DateTimeRange value={row[column.name]} /> }
    { column.type === "fulldatetime" && <DateTimeRange value={row[column.name]} fullDate /> }
    { column.type === "float" && <FloatFormat value={row[column.name]} /> }
    { column.type === "highlightedFloat" && <FloatFormat value={row[column.name]} color={'#EC4A00'} /> }
    { column.type === "dateRange" && <DateRange value={row[column.name]} /> }
    { column.type === "status" && <Status value={row[column.name]} /> }
    { column.type === "detailsFinReportStatus" && <FinReportStatus value={row[column.name]} /> }
    { column.type === "paymentHistoryStatus" && <PaymentHistoryStatus value={row[column.name]} /> }
    { column.type === "partnerStatus" && <PartnerStatus value={row[column.name]} isBlocked={row.is_blocked} /> }
    { column.type === "affiliateStatus" && <Status value={row[column.name]} /> }
    { column.type === "link" && <Link className={styles.link} value={row[column.name]} /> }
    { column.type === "arrayOfStrings" && <ArrayOfStrings value={row[column.name]} maxWidth={140} /> }
    { column.type === "mediumArrayOfStrings" && <ArrayOfStrings value={row[column.name]} maxWidth={240} /> }
    { column.type === "geoArray" && <ObjArray prefix="common.components.creative.list_geolocales." value={row[column.name]} maxWidth={84} /> }
    { column.type === "countriesArray" && <CountriesArray prefix="common.components.creative.list_geolocales." value={row[column.name]} maxWidth={230} /> }
    { column.type === "brandsArray" && <ObjArray value={row[column.name]} maxWidth={84} /> }
    { column.type === "languageArray" && <ObjArray prefix="common.components.creative.list_locales." value={row[column.name]} maxWidth={84} /> }
    { column.type === "productString" && <TranslationText prefix="common.components.creative.list_product." value={row[column.name]} /> }
    { column.type === "conceptString" && <TranslationText whiteSpace prefix="common.components.creative.list_concept." value={row[column.name]} /> }
    { column.type === "preview" && <Preview value={row[column.name]} /> }
    { column.type === "screen" && <Preview value={{ url: row[column.name] }} /> }
    { column.type === "adjustment" && <Adjustment value={row} /> }
    { column.type === "toggleWebhookActive" && <Switcher webhook={row} value={row[column.name]} /> }
    { column.type === "mail" && <EmailLink value={row[column.name]} maxWidth={150} /> }
    { column.type === "emailWithConfirmation" && <EmailLink value={row[column.name]} confirmed={row.is_email_confirmed} showConfirmedIcon maxWidth={150} /> }
    { column.type === "tooltipText" && <TooltipText value={row[column.name]} maxWidth={150} /> }
    { column.type === "contact" && <Contact type={row[column.name]} value={row.contact_value} /> }
    { column.type === "trafficUrl" && <Link value={row[column.name]} width={180} /> }
    { column.type === "manager" && <Manager username={row.manager_username} id={row.manager_id} width={120} maxWidth={120} /> }
    { column.type === "managerTeam" && <DepartmentTeam team={row.manager_team} /> }
    { column.type === "partnersCommunicationStatus" && <CommunicationStatus data={row} extraSmall={true} /> }
    { column.type === "partnersTrafficStatus" && <TrafficStatus data={row} extraSmall={true} /> }
    { column.type === "partnersCommunicationDate" && <CommunicationDate data={row} /> }
    { column.type === "condition" && <Condition name={row.condition_name} id={row.condition_id} /> }
    { column.type === "masterAffiliate" && <MasterAffiliate name={row.master_name} id={row.master_id} width={120} maxWidth={120} /> }
    { column.type === "shortString" && <TooltipText value={row[column.name]} width={160} maxWidth={160} /> }
    { column.type === "mediumString" && <TooltipText value={row[column.name]} width={280} maxWidth={280} /> }
    { column.type === "notes" && <TooltipText value={row[column.name]} width={240} maxWidth={240} /> }
    { column.type === "shortUsername" && <TooltipText value={row[column.name]} width={120} maxWidth={120} /> }
    { column.type === "communicationHistoryStatus" && <Status value={row[column.name]} /> }
    { column.type === "createdBy" && <Manager username={row.created_by.username} id={row.created_by.id} /> }
    { column.type === "updatedBy" && <Manager username={row.updated_by.username} id={row.updated_by.id} /> }
    { column.type === "created_by" && <Manager username={row.created_by.username} id={row.created_by.id} /> }
    { column.type === "updated_by" && <Manager username={row.updated_by.username} id={row.updated_by.id} /> }
    { column.type === "invitationManager" && <MasterAffiliate name={row.invitation_manager} id={row.invitation_manager_id} width={120} maxWidth={120} /> }
    { column.type === "token" && <Text value={row[column.name]} whiteSpace={true} /> }
    { column.type === "detailFinReportComment" && <DetailFinReportRowComment data={row} /> }
    { column.type === "creative_group" && <MasterAffiliate name={row.creative_name} id={row.creative_id} width={200} maxWidth={200} /> }
    { column.type === "tracker_group" && <MasterAffiliate name={row.tracker_description} id={row.tracker_id} width={200} maxWidth={200} /> }
    { column.type === "affiliate_group" && <MasterAffiliate name={row.affiliate_name} id={row.affiliate_id} width={200} maxWidth={200} /> }
    { column.type === "tracker_aasm_state" && <TranslationText value={row[column.name]} prefix={'common.components.trackers_aasm_state.'} /> }
    { column.type === "type_of_transaction" && <TranslationText value={row[column.name]} prefix={'common.components.type_of_transaction.'} /> }
    { column.type === "creative_type" && <TranslationText value={row[column.name]} prefix={'admin.creatives.form.format.options.'}/> }
    { column.type === "useAsTrafficBack" && <CheckedField value={row[column.name]} /> }
    { column.type === "objArrayMd" && <ObjArray value={row[column.name]} maxWidth={200} /> }
    { column.type === "billing_string_with_btn" && <TextWithBtn value={row[column.name]} whiteSpace /> }
    { column.type === "paymentHistoryDescription" && <PaymentDescription data={row} /> }
    { column.type === "AdjustmentReason" && <AdjustmentReason value={row[column.name]} bold={true} prefix="admin.details_finance_report.adjustments.options." /> }
    { column.type === "AdjustmentCreatedBy" && <AdjustmentReason value={row[column.name]} /> }
    { column.type === "AdjustmentStatus" && <AdjustmentStatus value={row[column.name]} /> }
    { column.type === "logsItem" && <LogsItem data={row['context']} action={row[column.name]} /> }
    { column.type === "affPaymentDetails" && <AffPaymentDetails data={row} value={row[column.name]} /> }
    { column.type === "admPaymentDetails" && <AdmPaymentDetails data={row} value={row[column.name]} /> }
    { column.type === "stringWithPrefix" && <TranslationText value={row[column.name]} prefix={column.prefix}/> }
    { column.type === "wallet" && <Wallet data={row} /> }
    { column.type === "currency" && <Currency value={row[column.name]} whiteSpace /> }
    { column.type === "floatOrNull" && <FloatOrNull value={row[column.name]} /> }
    { column.type === "clickableUserId" && <ClickableUserId value={row[column.name]} /> }
  </>;

  return (
    <Box className={`${styles.field} ${inline ? `inline` : ''}`}>
      {inline ? (
        <>
          {
            column.type !== "finAffId" && (
              <Box className={`${styles.label} label`}>
                <Typography variant="body2" className={styles.labelColor}>
                  {name}{inline && ':'}
                </Typography>
              </Box>
            )
          }
          <Box className={`${styles.fieldContent} content`}>
            <Typography variant="body2">
              {fields}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          {
            column.type !== "finAffId" && (
              <Box className={`${styles.label} label`}>
                <InputLabel>{name}{inline && ':'}</InputLabel>
              </Box>
          )}
          <Box className={`${styles.fieldContent} content`}>
            {fields}
          </Box>
        </>
      )}
    </Box>
  );
};

export default DetailsInfoBlock;
