//core
import React, {useEffect, useState} from "react";
import { makeStyles } from "@mui/styles";
import {numberToLocaleFormat, numberToLocaleFormatInteger, strToNumberFormat} from "../../../../../helpers/utils";
import { Box } from "@mui/material";
import {useTranslation} from "react-i18next";

interface IValue {
  value: string | number;
  color?: string;
}

const useStyles = makeStyles({
  text: {}
});

const FloatOrNull: React.FC<IValue> = ({
  value,
  color = '',
}): JSX.Element => {
  const styles = useStyles();
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState<string>('en');

  const renderValue = (!!value && value !== '∞' && value !== 'N/A')
      ? !`${value}`.includes('.')
        ? numberToLocaleFormatInteger(value, locale)
        : numberToLocaleFormat(value, locale)
      : value;

  const textColor = ((renderValue !== '0.00') && (renderValue !== '0,00') && (renderValue !== '0') && (renderValue !== 0))
    ? !!color
      ? color
      : 'inherit'
    : 'inherit';

  useEffect(() => {
    if (i18n.language === 'ru') {
      setLocale('ru');
    }

    if (i18n.language === 'en') {
      setLocale('en');
    }
  }, [i18n.language]);

  return (
    <Box component="span" className={styles.text} sx={{ color: textColor }}>
      { value === null || value === undefined
        ? '—'
        : value === 'N/A'
          ? 'N/A'
          : renderValue
      }
    </Box>
  );
};

export default FloatOrNull;
